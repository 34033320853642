import React from 'react';
import './App.css';

// Bootstrap
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'

// Components
import Header from './components/js/Header';
// import Footer from './components/js/Footer';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";
import Home from './components/js/Home';
import About from './components/js/About';
import Contact from './components/js/Contact';
import Services from './components/js/Services';
import Process from './components/js/Process';
import Kahaaniyaa from './components/projects/Kahaaniyaa';
import NotFound from './components/js/NotFound';

import $ from 'jquery'
// import NotFound from './components/js/NotFound';
// import GetInTouch from './components/js/GetInTouch';
// import {WOW} from 'wowjs'
import loader from './components/logo/keevii-orange.svg'



setTimeout(function () {
  $(".loader").slideUp();
}, 1500);

// setTimeout(function () {
//   $(".color__slide").slideUp();
// }, 2700);

 if (document.querySelector(".loader")) {
   document.querySelector(".loader")
   this.style.background = 'red'
 }


// const wow = new WOW();
// wow.init();


function App() {
  
  return (
    <div className="App">
      <Router>
        <div className="loader">
          <img src={loader} alt="" />
        </div>
        {/* <div id="color" className="color__slide"></div> */}
        <Header />
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/services" component={Services}></Route>
          <Route exact path="/process" component={Process}></Route>
          <Route exact path="/projects/kahaaniyaa" component={Kahaaniyaa}></Route>
          <Route component={NotFound}></Route>
        </Switch>
        
      </Router>
    </div>
  );

}



export default App;
