import React from 'react'
import nf from '../stage/404.svg'
import { Link } from 'react-router-dom'

import '../css/NotFound.css'
import { Helmet } from 'react-helmet'

function NotFound() {
    return (
        <div>

            <Helmet>
                <title>Keevii - 404</title>
            </Helmet>

            <div id="notfound">
                <img src={nf} alt="" />
                <p>Oops Looks like you have Lost. <br /> Go to right place <span><Link to="/" className="home__link">Let's Go</Link></span> </p>
            </div>
        </div>
    )
}

export default NotFound
