// import React from 'react'
import React, { Component } from 'react';
// Style
import '../css/GetInTouch.css'

import { Link } from 'react-router-dom'

// function GetInTouch() {

    

//     return (
//         <div id="getInTouch">
//             <div className="container">
//                 <h1>Let's pursuit great together!</h1>

//                 <Link className="contact-btn" onClick={reload} to="/contact">Get in touch</Link>
//             </div>
//         </div>
//     )
// }

// export default GetInTouch


class GetInTouch extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    };

  render() {
    return (
      <div>
          <div id="getInTouch">
            <div className="container">
                <h1>Let's pursuit great together!</h1>

                <Link className="contact-btn" to="/contact">Get in touch</Link>
            </div>
        </div>
      </div>
    );
  }
}

export default GetInTouch;
