import React from 'react'
import '../css/Card.css'

function Card({ icon, heading, info }) {
    return (
        <div>
            <div className="card">
                <img src={icon} alt="icon" />
                <h3>{heading}</h3>
                <p>{info}</p>
            </div>
        </div>
    )
}

export default Card
