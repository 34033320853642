import React, { useEffect } from 'react'
import { Link } from "react-router-dom";

// Styles
import '../css/Footer.css'


function reload() {
    // window.scrollTo(0, 0);
}


function Footer() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div id="footer">
            {/* <Router> */}
                <div className="container">
                    <div className="footer-elements">
                        <div className="footer__links">
                            <ul>
                                <li><Link onClick={reload} className="footer__link" to="/">Home</Link></li>
                                <li><Link onClick={reload} className="footer__link" to="/process">Process</Link></li>
                                <li><Link onClick={reload} className="footer__link" to="/services">Services</Link></li>
                            </ul>

                            <ul>
                                <li><Link onClick={reload} className="footer__link" to="/about">About</Link></li>
                                <li><Link onClick={reload} className="footer__link" to="/contact">Contact</Link></li>
                            </ul>   
                        </div>
                            {/* <ul>
                                <li><Link onClick={reload} className="footer__links" to="/">Home</Link></li>
                                <li><Link onClick={reload} className="footer__links" to="/services">Services</Link></li>
                                <li><Link onClick={reload} className="footer__links" to="/about">About</Link></li>
                                <li><Link onClick={reload} className="footer__links" to="/contact">Contact</Link></li>
                            </ul> */}

                            <hr/>

                            <div className="footer-bottom">
                                <div className="footer-left">
                                    <p>&copy; 2021 Keevii Technologies . All rights reserved.</p>
                                </div>
                                <div className="footer-left ">
                                    <p>Designed with ❤ somewhere peacefull.</p>
                                </div>
                            </div>

                        
                    </div>
                </div>
            {/* </Router> */}
        </div>
    )
}

export default Footer
