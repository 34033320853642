import React from 'react'

import '../css/Project.css'

import khBanner from '../project-images/kahaaniyaa/kahaaniyaa-banner.jpg'
import khHome from '../project-images/kahaaniyaa/kahaaniyaa-head.jpg'
import khArticle from '../project-images/kahaaniyaa/kahaaniyaa-posts.jpg'
import khDetail from '../project-images/kahaaniyaa/kahaaniyaa-detail.jpg'
import khLogin from '../project-images/kahaaniyaa/kahaaniyaa-login.jpg'
import khSignup from '../project-images/kahaaniyaa/kahaaniyaa-signup.jpg'
import khTypo from '../project-images/kahaaniyaa/kahaaniyaa-typo.jpg'
import khColor from '../project-images/kahaaniyaa/kahaaniyaa-color.jpg'
import khTech from '../project-images/kahaaniyaa/kahaaniyaa-tech.jpg'

import $ from "jquery";
import Footer from '../js/Footer'
import Preloader from '../js/Preloader'

function Kahaaniyaa() {
    setTimeout(function () {
        $(".loader").slideUp();
    }, 1400);
    return (
        <div>
            <Preloader />

            <div id="project">
                <div className="container">
                    <h1 className="project_name" >Kahaaniyaa</h1>
                    <h3 className="project_head">A Storyteller</h3>
                </div>
                <div className="project_banner">
                    <img src={khBanner} alt="Kahaaniyaa-Banner" />
                </div>

                <div className="container introduction">
                    <h2 className="project_intro">Introduction</h2>
                    <p className="project_desc">Kahaaniyaa is storytelling web based platform which provides interesting stories, like Horror Stories, Love Stories and many more only for Entertainment.</p>
                </div>

                <div id="project-main">
                    <div className="container">
                        <div className="role_section">
                            <h3>Our Role behind this:</h3>
                            <p>We build this with our process of Strategy, Design, Implementation.</p>
                            <div className="role">
                                <ul>
                                <li>- Requirement Analysis</li>
                                <li>- Design</li>
                                <li>- Development</li>
                            </ul>
                            <ul>
                                <li>- Coding</li>
                                <li>- Testing</li>
                                <li>- Deployment</li>
                            </ul>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className="row process justify-content-center">
                            <div className="col-md-6">
                                <h2 className="process_main_head">Our Process</h2>
                                <div className="process_state process_strategy">
                                    <h2 className="process_heading">Strategy</h2>
                                    <p className="process_desc">Kahaaniyaa is a strorytelling platform which needs lots of of traffic, for that we had to make it SEO friendly and user friendly.
                                    It should to easy to use for user both readers to explore stories and writers to write.</p>
                                </div>
                                <div className="process_state process_design">
                                    <h2 className="process_heading">Design</h2>
                                    <p className="process_desc">Design is important role while building applications. Selecting good color theme, Typography, Whitspace, Visual Hierarchy are the main topics of design. The main rule of design is keep it simple and solid.</p>
                                </div>
                            </div>
                        </div>

                        <div id="design_screens">
                            <div className="container">
                                <div className="design_screen_each">
                                    <h4 className="design_screen_heading">Home Page</h4>
                                    <img src={khHome} alt="" />
                                </div>

                                <div className="design_screen_each">
                                    <h4 className="design_screen_heading">Article Page</h4>
                                    <img src={khArticle} alt="" />
                                </div>

                                <div className="design_screen_each">
                                    <h4 className="design_screen_heading">Article Detail Page</h4>
                                    <img src={khDetail} alt="" />
                                </div>

                                <div className="design_screen_each">
                                    <h4 className="design_screen_heading">Login and Register Page</h4>
                                    <div className="login_reg_flex">
                                        <div>
                                            <img src={khLogin} alt="" />
                                        </div>
                                        <div>
                                            <img src={khSignup} alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div className="design_screen_each">
                                    <img src={khTypo} alt="" />
                                </div>

                                <div className="design_screen_each">
                                    <img src={khColor} alt="" />
                                </div>
                            </div>
                            
                        </div>

                        
                        <div className="row process justify-content-center">
                            <div className="col-md-6">
                                <div className="process_state process_implementation">
                                    <h2 className="process_heading">Implementation</h2>
                                    <p className="process_desc">This is the process where strategy meets action to get desired product. It involves team to do work as per the design.
                                        Testing of the product met with final product as per customers requirements. Deployment on the server.</p>
                                </div>

                                <div className="tech_stack">
                                    <h3 className="teck_stack_head">TECHNOLOGY STACK</h3>
                                    <img src={khTech} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    )
}

export default Kahaaniyaa
