import React from 'react'

import '../css/Preloader.css'
// import $ from "jquery";

import loader from "../logo/keevii-orange.svg";

// setTimeout(function () {
//   $(".loader").slideUp();
// }, 2200);

// setTimeout(function () {
//   $(".color__slide").slideUp();
// }, 2700);


function Preloader() {
    return (
      <div>
        <div className="loader">
          <img src={loader} alt="" />
        </div>
      </div>
    );
}

export default Preloader
