import React from 'react'
import keevii from '../logo/keevii-black.svg'
// import typo from '../logo/keevii-logo-typo.svg'
import menu from '../logo/menu.svg'
import close from '../logo/close.svg'
import { Link } from "react-router-dom";
import '../css/Header.css'

// Components
// import Home from './Home'
// import About from './About'
// import Contact from './Contact'
// import Services from './Services';

function reload() {
    document.getElementById("myNav").style.height = "0%";
    document.getElementById("myNav").style.transition = "400ms";
    window.scrollTo(0, 0)
}


function Header() {
    return (
      <div className="header">
        {/* <Router> */}
        <div className="header_menu">
          <div>
            <Link className="header_logo" onClick={reload} to="/">
              <img width="20px" style={{'padding': '1px'}} src={keevii} alt="Logo" />
              {/* <img width="112" src={typo} alt="log-typo" /> */}
            </Link>
          </div>
          <div>
            <img className="openBtn" onClick={openNav} width="25" src={menu} alt="" />
          </div>
        </div>
        <div>
          <div id="myNav" style={{ overflow: "hidden" }} className="overlay">
            <span href="/" className="closebtn" onClick={closeNav}>
              <img src={close} alt="" />
            </span>
            <div className="overlay-content ">
                <Link onClick={reload} className="link link1" to="/">
                  Home
                </Link>
                <Link onClick={reload} className="link link2" to="/process">
                  Process
                </Link>
                <Link onClick={reload} className="link link3" to="/services">
                  Services
                </Link>
                <Link onClick={reload} className="link link4" to="/about">
                  About
                </Link>
                <Link onClick={reload} className="link link5" to="/contact">
                  Contact
                </Link>
            </div>
          </div>
        </div>

        {/* <Route exact path="/" component={Home}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route exact path="/contact" component={Contact}></Route>
          <Route exact path="/services" component={Services}></Route> */}
        {/* </Router> */}
      </div>
    );
}

function openNav() {
    document.getElementById("myNav").style.height = "100%";
    document.getElementById("myNav").style.overflow = "hidden";
    document.getElementById("myNav").style.transition = "300ms";
}

function closeNav() {
    document.getElementById("myNav").style.height = "0%";
    document.getElementById("myNav").style.transition = "400ms";
    // window.scrollTo(0, 0)
}

export default Header
