import React from 'react'

// Style
import '../css/Strategy.css'


// Images
import strategy from '../images/strategy.jpg'
import design from '../images/design.jpg'
import innovation from '../images/innovation.jpg'


function Strategy() {
    return (
        <div>
            <div id="strategy" className="aos-init aos-animate">
                <div className="container">
                    <h2 className="ml-2" >What we do</h2>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 col-lg-4">
                        <img className="aos-init aos-animate"  data-aos-duration="1500" src={strategy} alt="strategy" />
                    </div>

                    <div className="col-md-5 offset-md-1 strategy cards">
                        <div>
                            <h2 className=" " data-aos="" data-aos-duration="1500">STRATEGY</h2>
                            <h3 className="  mb-2"  data-aos-duration="1500">&ldquo;What we are going to do...&rdquo;</h3>

                            <div className="main-strategy-work">
                                <div className="main-strategy">
                                    <ul className=" "  data-aos-duration="1500" data-aos-delay="50">
                                        <li>Responsive Design</li>
                                        <li>Good team</li>
                                        <li>Users priority</li>
                                    </ul>
                                </div>
                                <div className="main-strategy">
                                    <ul className=" "  data-aos-duration="1500" data-aos-delay="50">
                                        <li>Versatility</li>
                                        <li>Customize Design</li>
                                        <li>Testing and Validation</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 col-lg-4">
                        <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" src={design} alt="design" />
                    </div>

                    <div className="col-md-5 offset-md-1 strategy cards">
                        <h2 className=" " data-aos="" data-aos-duration="1500" >DESIGN</h2>
                        <div className="main-strategy-work">
                            <div className="main-strategy design">
                                <p className=" "  data-aos-duration="1500">&ldquo;Design is much more important part it development, it shows working of the product and how the product looks.&rdquo;</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-md-5 col-lg-4">
                        <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" src={innovation} alt="innovation" />
                    </div>

                    <div className="col-md-5 offset-md-1 strategy cards">
                        <h2 className=" " data-aos="" data-aos-duration="1500" >INNOVATION</h2>
                        <div className="main-strategy-work">
                            <div className="main-strategy design">
                                <p className=" " data-aos-duration="1500">&ldquo;Refers to creating more effective processes, products, and ideas. Making changes in the existing environment to deliver better products or services. &rdquo;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Strategy
