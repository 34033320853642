import React from 'react'
import { Link } from 'react-router-dom'
import '../css/Work.css'

import kahaaniyaa from '../project-images/all-images/kahaaniyaa.jpg'
import besttocart from '../project-images/all-images/besttocart.jpg'


function reload() {
    window.scrollTo(0, 0);
}

function Work() {
    return (
        <>
            <div id="Work">
                <div className="container">
                    <h2 className="work__heading">Our Work</h2>
                    <div className="row">
                        <div className="col-sm-6">
                            <Link onClick={reload} to="/projects/kahaaniyaa" style={{textDecoration: 'none'}}>
                                <div className="work_header kahaaniyaa_bg">
                                    <img src={kahaaniyaa} class="card-img-top" alt="..." />
                                </div>
                                
                                <div className="work-body">
                                    <h4 className="card-title">Kahaaniyaa.in - A Storyteller</h4>
                                    <p className="work-type">Story telling website</p>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-6">
                            <Link to="#" style={{textDecoration: 'none'}}>
                                <div className="work_header besttocart_bg">
                                    <img src={besttocart} class="card-img-top" alt="..." />
                                </div>
                                
                                <div className="work-body">
                                    <h4 className="card-title">BestToCart</h4>
                                    <p className="work-type">product review website</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Work
