import React from 'react'

// Images
import strategy from '../icons/strategy.svg'
import design from '../icons/design.svg'
import implement from '../icons/implement.svg'

import des from '../process/desig.jpg'
import imp from '../process/impl.jpg'
import strat from '../process/strat.jpg'

// Style
import '../css/Process.css'
import { Helmet } from 'react-helmet'
import GetInTouch from './GetInTouch'
import Footer from './Footer'
import Preloader from './Preloader'

import $ from "jquery";


function Process() {

    setTimeout(function () {
      $(".loader").slideUp();
    }, 1000);

    // setTimeout(function () {
    //   $(".color__slide").slideUp();
    // }, 2300);
   
    return (
        <>
            <Helmet>
                <title>Keevii - Process</title>
            </Helmet>

            <Preloader />
        
            <div id="process">
                <div className="container">
                    <h2>Process</h2>
                    <h3 className="process__subhead ml-2">How it works</h3>
                    
                    <div className="row">
                        <div className="col-lg-4 col-md-5 column">
                            <div className="process__card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                                <img src={strategy} alt="strategy" />
                                <h3>STRATEGY</h3>
                                <p>Finding solution on software requirements and making strategy to solve the problem.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 column">
                            <div className="process__card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                <img src={design} alt="design" />
                                <h3>DESIGN</h3>
                                <p>Wireframing and prototyping till getting the highest peak for design. From paper to screen.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5 column">
                            <div className="process__card aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                                <img src={implement} alt="implement" />
                                <h3>IMPLEMENTATION</h3>
                                <p>The process where putting all the strategy into action with for solving the problem. </p>
                            </div>
                        </div>
                    </div>
                    


                    <div className="process__section__two container">
                        {/* Strategy Process */}
                        <div className="row row__gap">
                            <div className="col-lg-4">
                                <span className="span span__strategy">Strategy</span>
                                <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700" src={strat} alt="Strategy" />
                            </div>
                            <div className="col-lg-6 offset-lg-2 process__info strategy__info">
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">A set of upfront decisions that allows you to come up with an effective set of dos, don’ts.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">Analysing all software requirements by the customer and making decisions.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">It involves the project team, and it includes project plan, cost estimation, time requirement for completing the project.</p>
                                <div className="key__proc key__proc__strat aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700">
                                    <h4>Key Process:</h4>
                                    <div className="poceess__feat">
                                        <span>Requirement Analysis</span>  
                                        <span>Cost Estimation</span>                                  
                                    </div>
                                    <div className="poceess__feat">
                                        <span>Brainstroming</span> 
                                        <span>Defining Problem</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Design Process */}
                        <div className="row row__gap">
                            <div className="col-lg-4">
                                <span className="span span__design">Design</span>
                                <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700" src={des} alt="Design" />
                            </div>
                            <div className="col-lg-6 offset-lg-2 process__info design__info">
                                <div className="quote aos-init aos-animate"  data-aos="fade-up" data-aos-duration="1500">
                                    <p><em>“ Design is not just what it looks like and feels like. Design is how it works.”</em></p>
                                    <span className="float">- Steve Jobs</span>
                                </div>
                                <br/>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">Design is the visualization of a system, it includes wireframing, prototyping.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">Testing the output of the product in design phase as per customer requirement</p>
                                <div className="key__proc key__proc__design aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700">
                                    <h4>Key Process:</h4>
                                    <div className="poceess__feat">
                                        <span>WireFraming</span>  
                                        <span>Prototyping</span>                                  
                                        <span>User Flow</span>                                  
                                    </div>
                                    <div className="poceess__feat">
                                        <span>User Interface</span> 
                                        <span>User Experience</span>
                                    </div>
                                    <div className="poceess__feat">
                                        <span>Testing</span> 
                                        <span>Whitebox Testing</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Implementation Process */}
                        <div className="row row__gap">
                            <div className="col-lg-4">
                                <span className="span span__impl">Implementation</span>
                                <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700" src={imp} alt="Implementation" />
                            </div>
                            <div className="col-lg-6 offset-lg-2 process__info impl__info">
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">This is the process where strategy meets action to get desired product. It involves team to do work as per the design.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">This phase comes with development team together for solving the problem by coding and testing of the product.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">Testing of the product met with final product as per customers requirements.</p>
                                <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">Finding errors or bug happend while coding phase and solve them.</p>
                                <div className="key__proc key__proc__impl aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700">
                                    <h4>Key Process:</h4>
                                    <div className="poceess__feat">
                                        <span>Coding</span>  
                                        <span>Development</span>                                  
                                        <span>Code Review</span>                                  
                                    </div>
                                    <div className="poceess__feat">
                                        <span>Application Design</span> 
                                        <span>Whitebox Testing</span>
                                    </div>
                                    <div className="poceess__feat">
                                        <span>Blackbox Testing</span> 
                                        <span>Deployment</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <GetInTouch />
            <Footer />
        </>
    )
}

export default Process
