import React, { useEffect } from 'react'

// Style
import '../css/About.css'

// Image
import wheel from '../images/keevii-wheel.svg'
import keeviimain from '../images/keevii-main.svg'
// import loader from "../logo/keevii-orange.svg";

import { Link } from 'react-router-dom'
import GetInTouch from './GetInTouch'
import { Helmet } from 'react-helmet'
import Footer from './Footer'

import $ from "jquery";
import Preloader from './Preloader';

function reload() {
    // window.scrollTo(0,0)
}


function About() {

    setTimeout(function () {
      $(".loader").slideUp();
    }, 1000);
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // setTimeout(function () {
    //   $(".color__slide").slideUp();
    // }, 2300);

    // componentDidMount() {
    //     window.scrollTo(0, 0);
    // }


    return (
      <>
        <Helmet>
          <title>Keevii - About</title>
        </Helmet>

        {/* <div>
          <div className="loader">
            <img src={loader} alt="" />
          </div>
          <div id="color" className="color__slide"></div>
        </div> */}

        <Preloader />

        <div id="about">
          <div className="container">
            <h2>We're a Team</h2>

            <div className="row justify-content-center">
              <div className="col-md-5 card-info">
                <div>
                  <p>
                    We are different kind of fruits of single tree, each fruit
                    have geeky ability. Our vision is same that sticks us
                    together in a team. Our main purpose is to make people happy
                    and make their life easier.
                  </p>
                  <p>
                    &ldquo; We create impactful software solutions for people, to grow their work.  &rdquo;
                  </p>
                  
                
                </div>
              </div>
              <div className="col-md-5 col-lg-4 offset-md-1 about-img-container">
                <img src={wheel} alt="Keevii About" />
                <img src={keeviimain} alt="Keevii About" />
              </div>
            </div>

            <div className="service-btn">
              <p>We provide very usefull services, get reach there,</p>
              <Link className="button" onClick={reload} to="/services">
                SERVICES
              </Link>
            </div>
          </div>
        </div>
        <GetInTouch />
        <Footer />
      </>
    );
}

export default About