import React, { useEffect } from 'react'

// Helmet
import { Helmet } from 'react-helmet'
import GetInTouch from "./GetInTouch";

// Styles 
import '../css/Services.css'
import Card from './Card'

import $ from "jquery";

// Image 
import performance from '../services/performance.svg'
import ecomm from '../services/ecomm.svg'
import web from '../services/web.svg'
import responsive from '../services/responsive.svg'
import rocket from '../services/rocket.svg'
import seo from '../services/seo.svg'
import work from '../images/work.jpg'
import Footer from './Footer';
import Preloader from './Preloader';



function Services() {

    setTimeout(function () {
      $(".loader").slideUp();
    }, 1000);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // setTimeout(function () {
    //   $(".color__slide").slideUp();
    // }, 2300);


    return (
      <>
        {/* <Preloader /> */}
        <Helmet>
          <title>Keevii - Services</title>
        </Helmet>

        <Preloader />

        <div id="main__services">
          <div className="container">
            <h2> Our Services</h2>

            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={performance}
                  heading="Performance"
                  info="Websites should be load in less time. Fast load times and lag free interaction, Our highest priority."
                />
              </div>
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={web}
                  heading="Web Design"
                  info="Interact with customers with a great looking website that is easy-to-use and simple to edit with our Website design services."
                />
              </div>
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={ecomm}
                  heading="Ecommerce"
                  info="Go farther with your product. Get your products online and reach out to many people."
                />
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={rocket}
                  heading="Dynamic Nature"
                  info="Applications don't have to be Static. We love making pages come to life. Applications should be user friendly."
                />
              </div>
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={responsive}
                  heading="Responsive Behaviour"
                  info="Responsive Web design is the approach that suggests that design and development should respond to the user’s behavior and environment based on screen size, platform and orientation."
                />
              </div>
              <div className="col-lg-3 col-md-4">
                <Card
                  icon={seo}
                  heading="SEO"
                  info="Get your website on top of search engine. Optimizing content to be discovered through a search engine’s organic search results. "
                />
              </div>
            </div>
          </div>


          <div className="service__caption">
            <h4 className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">KUDOS</h4>
            <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">&ldquo; Websites keeps them coming back &rdquo;</p>
          </div>


          <div className="service__values">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-5 col-lg-4">
                  <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="1700" src={work} alt="Work" />
                </div>
                <div className="col-md-5 offset-md-1 cards">
                  <h2>You know it well what you're good at in.</h2>
                  <p>
                    &ldquo; The results of your work should be clear, so you
                    need to specify it in well manner in the format of web. That
                    it can be reach to many people. &rdquo;
                  </p>
                  <p>Business's should be scalable to reach out many people.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <GetInTouch />
        <Footer />
      </>
    );
}

export default Services
