import React, { useEffect } from 'react'

import success from '../images/success.png'

// Styles
import '../css/Contact.css'

// Component
import { Helmet } from 'react-helmet'
import emailjs from "emailjs-com";  
import Footer from './Footer';
import Preloader from './Preloader';

import $ from "jquery";

function sendEmail(e) {
    e.preventDefault()
    console.log(`Form Submitted`);

    emailjs.sendForm("gmail", "template_keevii", e.target, "user_EebBb2HY5IcH1cMpxxl2c").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset()


    const flash = document.querySelector(".flash__message");
    flash.classList.add('flash-message')
    window.scrollTo(0, 0);
    setTimeout(() => {
        flash.classList.remove('flash-message') 
    }, 9000);
}




function Contact() {
  
    setTimeout(function () {
      $(".loader").slideUp();
    }, 1000);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // setTimeout(function () {
    //   $(".color__slide").slideUp();
    // }, 2300);

    // componentDidMount() {
    //     window.scrollTo(0, 0);
    // }

    return (
      <div>
        <Helmet>
          <title>Keevii - Contact</title>
        </Helmet>

        <Preloader />

        <div id="contact">
          <div className="container">
            <div className="flash__message">
              <span>
                <img className="mb-2" src={success} alt="" />
              </span>
              <p>We appreciate you contacting Keevii</p>
              <p>
                We will get back in touch with you soon!
                <br />
                Have a great day! 🙂
              </p>
            </div>
            <h2 className="contact__heading">Contact Us</h2>
            <div className="row justify-content-center">
              <div className="col-md-4 contact-left">
                <h3>Let's Talk</h3>
                <ul>
                  <li>Start Project</li>
                  <li>Any Queries?</li>
                  <li>Enquiry</li>
                </ul>
              </div>
              <div className="col-md-7 offset-md-1 contact-right">
                <h2>Let's pursuit great together!</h2>
                <div className="contact-form">
                  <form className="form" onSubmit={sendEmail} action="">
                    <div className="input">
                      <input type="text" placeholder="Full Name" name="name" required="true" />
                    </div>

                    <div className="input">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        name="phone"
                        required="true"
                      />
                    </div>
                    <div className="input">
                      <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        required="true"
                      />
                    </div>

                    <h4>Give us message what you want from us?</h4>

                    <textarea
                      type="text"
                      placeholder="Message, Starting a Project, Work etc"
                      name="message"
                      required="true"
                    ></textarea>

                    <div>
                      <button type="submit" className="contact__button">SEND</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
}

export default Contact
