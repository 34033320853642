import React, { useEffect } from 'react'

// CSS
import '../css/Home.css'

// Images
import stage from '../stage/keevii-banner-1.svg'
import performance from '../services/performance.svg'
import ecomm from '../services/ecomm.svg'
import web from '../services/web.svg'

import $ from "jquery";

// Component
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Card from './Card'
import Strategy from './Strategy'
import Technology from './Technology'
import GetInTouch from './GetInTouch'
import Footer from './Footer'
import Work from './Work'
import Preloader from './Preloader'


function reload() {
    // window.scrollTo(0, 0)
}


function Home() {

    setTimeout(function () {
      $(".loader").slideUp();
    }, 1200);

    // setTimeout(function () {
    //   $(".color__slide").slideUp();
    // }, 2300);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>

            <Helmet>
                <title>Keevii - Web Based Product Design and Development Company</title>
            </Helmet>

            <Preloader />

            {/* Banner  */}
            <div id="banner" className="banner container">
                <div className="row justify-content-center align-items-center">
                    <div id="stage-left" className="col-md-6 col-xs-10">
                        <div className="stage-caption" >
                            <p className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="5000" data-aos-delay="100">We're Web based product <span className="span-one">design</span> and <span className="span-two">development</span> company.</p>
                        </div>
                        <div className="stage-services">
                            <h2> We Build
                            <div className="inner" >
                                <span>
                                    Websites <br/> Designs <br/> Apps <br/> UI/UX
                                </span>
                            </div>
                            </h2>
                        </div>
                    </div>
                    <div id="stage-right" className="col-md-6" >
                        <span className="img-responsive green__cir"></span>
                        <img className="aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1500" src={stage}  alt="banner" />
                        <span className="red__cir"></span>
                    </div>                
                </div>
            </div>


            {/* Services */}
            <div id="services">
                <div className="container">
                    <h2 className="service__head aos-animate ml-2" >Services</h2>
                    <p className="service__title">We serve something better</p>

                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100" >
                            <Card
                                icon={performance}
                                heading="Performance"
                                info="Websites should be load in less time. Fast load times and lag free interaction, Our highest priority." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="200">
                            <Card
                                icon={web}
                                heading="Web Design"
                                info="Interact with customers with a great looking website that is easy-to-use and simple to edit with our Website design services." 
                            />
                        </div>
                        <div className="col-lg-3 col-md-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                            <Card
                                icon={ecomm}
                                heading="Ecommerce"
                                info="Go farther with your product. Get your products online and reach out to many people." 
                            />
                        </div>
                    </div>

                    <div className="service__btn">
                        <h3 className="mb-3 aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1000" >There is something more</h3>
                        <div> 
                            <Link onClick={reload} className="seeMore" to="/services">See More</Link>
                        </div>
                    </div>
                </div>
            </div>



            {/* Work */}
            <div>
                <Work />
            </div>




            {/* Strategy */}
            <div >
                <Strategy/>
            </div>

            {/* Technology */}
            <div>
                <Technology />
            </div>

            <div>
                <GetInTouch />
            </div>

            <Footer />

        </>
    )
}

export default Home
