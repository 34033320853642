import React from 'react'

// CSS
import '../css/Technology.css'

// Image
import macbook from '../images/macbook.jpg'

function Technology() {
    return (
        <div>
            <div id="technology" className="aos-init aos-animate" data-aos="slide-right" data-aos-duration="1000">
                <div className="container">
                    <div className="tech-heading">
                        <h3 className="aos-init aos-animate" data-aos-duration="1000" data-aos="fade-in" data-aos-delay="1000">Next Stop, Technology</h3>
                        <h2 className="aos-init aos-animate ml-2" data-aos-duration="1000" data-aos="fade-in" data-aos-delay="1000">Technology</h2>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-5 tech-content">
                            <p className="aos-init aos-animate" data-aos-duration="1000" data-aos="fade-up" data-aos-delay="900">We're gonna use latest higher level technologies for developing applications.</p>

                            <ul className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="600" data-aos-duration="2000">
                                <li>HTML5</li>
                                <li>CSS3</li>
                                <li>React</li>
                                <li>Python</li>
                                <li>Django</li>
                                <li>Flask</li>
                                <li>PHP</li>
                                <li>PostgreSQL</li>
                                <li>MySQL</li>
                            </ul>
                        </div>

                        <div className="col-md-5 offset-md-1">
                            <img className="aos-init aos-animate" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="700" src={macbook} alt="mackbook" />
                        </div>
                        </div>
                        
                    
                </div>
            </div>
        </div>
    )
}

export default Technology
