import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


// import '../node_modules/magnet-mouse/lib/magnet-mouse.min.js'

import AOS from 'aos';
import '../node_modules/aos/dist/aos.css';
import '../node_modules/aos/dist/aos.js';

// import MagnetMouse from "magnet-mouse";


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


AOS.init();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
